import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  currentAdv: null,
  couponList: [],
  couponListCount: 0
};

const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE USERS
    deleteCoupon(state, action) {
      const deleteCoupon = filter(state.couponList, (coupon) => coupon._id !== action.payload);
      state.couponList = deleteCoupon;
    },

    // GET MANAGE ADS
    getCouponListSuccess(state, action) {
      state.isLoading = false;
      state.couponList = action.payload.rows;
      state.couponListCount = action.payload.count;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteCoupon } = slice.actions;

// ----------------------------------------------------------------------

export function getCouponList(q = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/coupons', {
        params: q
      });
      dispatch(slice.actions.getCouponListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
