export function formatUserRole(role) {
  if (role === 'admin') {
    return 'Admin';
  }

  if (role === 'direttivo') {
    return 'Direttivo';
  }

  return 'Socio';
}

export function formatSport(sportKey){
  /*
  sport.cardio.archery	Tiro con l'arco
sport.cardio.badminton	Badminton
sport.cardio.baseball	Baseball
sport.cardio.basketball	Basketball
sport.cardio.billiards_pool	Biliardo
sport.cardio.bowling	Bowling
sport.cardio.boxing	Boxing
sport.cardio.car_racing	Macchine da corsa
sport.cardio.cricket	Cricket
sport.cardio.curling	Curling
sport.cardio.cycling	Ciclismo
sport.cardio.fencing	Scherma
sport.cardio.figure_skating	Pattinaggio
sport.cardio.fishing	Pesca
sport.cardio.fitness	Fitness/Palestra
sport.cardio.golf	Golf
sport.cardio.hang_gliding	Deltaplano
sport.cardio.high_jumping	Salto in alto
sport.cardio.hiking	Hiking
sport.cardio.hockey	Hockey
sport.cardio.horse_racing	Gare di corsa di cavallo
sport.cardio.horseback_riding	Equitazione
sport.cardio.judo	Judo
sport.cardio.karate_mix_martial_arts	Karate - Mescolare le arti marziali
sport.cardio.paddle	Paddle
sport.cardio.rugby	Rugby
sport.cardio.running	Corsa
sport.cardio.sailing	Vela
sport.cardio.shooting	Sparare
sport.cardio.skateboarding	Skateboarding
sport.cardio.ski	Sciare
sport.cardio.snowsports	Sport sulla neve
sport.cardio.soccer	Calcio
sport.cardio.surfing	Surf
sport.cardio.swimming	Nuoto
sport.cardio.swimming_sports	Sport d'acqua
sport.cardio.table_tennis	Ping-pong
sport.cardio.tennis	Tennis
sport.cardio.triatlon	Triatlon
sport.cardio.volleyball	Pallavolo
sport.cardio.walking	Camminare
sport.cardio.wrestling	Wrestling
sport.cardio.canoa_sport	Canoa
sport.cardio.kite	Kite
sport.cardio.bike	Bici
sport.cardio.bmx_bike	BMX
sport.flexibility.ballet	Danza classica
sport.flexibility.dancing	Danza
sport.flexibility.gymnastics	Ginnastica
sport.flexibility.pilates	Pilates
sport.flexibility.yoga	Yoga
sport.weight.bodyweight	Esercizi a corpo libero
sport.weight.calisthenics	Calisthenics
sport.weight.climbing	Arrampicata
sport.weight.crossfit	Crossfit
sport.weight.lifting	Sollevamento pesi
sport.weight.powerlifting	Powerlifting
sport.weight.strongman	Strongman
sport.weight.weightlifting	Pesistica
*/
  // the above is the mapping between sport key and sport name

  // create a map
  const sportsMap = {
    'sport.cardio.archery': 'Tiro con l\'arco',
    'sport.cardio.badminton': 'Badminton',
    'sport.cardio.baseball': 'Baseball',
    'sport.cardio.basketball': 'Basketball',
    'sport.cardio.billiards_pool': 'Biliardo',
    'sport.cardio.bowling': 'Bowling',
    'sport.cardio.boxing': 'Boxing',
    'sport.cardio.car_racing': 'Macchine da corsa',
    'sport.cardio.cricket': 'Cricket',
    'sport.cardio.curling': 'Curling',
    'sport.cardio.cycling': 'Ciclismo',
    'sport.cardio.fencing': 'Scherma',
    'sport.cardio.figure_skating': 'Pattinaggio',
    'sport.cardio.fishing': 'Pesca',
    'sport.cardio.fitness': 'Fitness/Palestra',
    'sport.cardio.golf': 'Golf',
    'sport.cardio.hang_gliding': 'Deltaplano',
    'sport.cardio.high_jumping': 'Salto in alto',
    'sport.cardio.hiking': 'Hiking',
    'sport.cardio.hockey': 'Hockey',
    'sport.cardio.horse_racing': 'Gare di corsa di cavallo',
    'sport.cardio.horseback_riding': 'Equitazione',
    'sport.cardio.judo': 'Judo',
    'sport.cardio.karate_mix_martial_arts': 'Karate - Mescolare le arti marziali',
    'sport.cardio.paddle': 'Paddle',
    'sport.cardio.rugby': 'Rugby',
    'sport.cardio.running': 'Corsa',
    'sport.cardio.sailing': 'Vela',
    'sport.cardio.shooting': 'Sparare',
    'sport.cardio.skateboarding': 'Skateboarding',
    'sport.cardio.ski': 'Sciare',
    'sport.cardio.snowsports': 'Sport sulla neve',
    'sport.cardio.soccer': 'Calcio',
    'sport.cardio.surfing': 'Surf',
    'sport.cardio.swimming': 'Nuoto',
    'sport.cardio.swimming_sports': 'Sport d\'acqua',
    'sport.cardio.table_tennis': 'Ping-pong',
    'sport.cardio.tennis': 'Tennis',
    'sport.cardio.triatlon': 'Triatlon',
    'sport.cardio.volleyball': 'Pallavolo',
    'sport.cardio.walking': 'Camminare',
    'sport.cardio.wrestling': 'Wrestling',
    'sport.cardio.canoa_sport': 'Canoa',
    'sport.cardio.kite': 'Kite',
    'sport.cardio.bike': 'Bici',
    'sport.cardio.bmx_bike': 'BMX',
    'sport.flexibility.ballet': 'Danza classica',
    'sport.flexibility.dancing': 'Danza',
    'sport.flexibility.gymnastics': 'Ginnastica',
    'sport.flexibility.pilates': 'Pilates',
    'sport.flexibility.yoga': 'Yoga',
    'sport.weight.bodyweight': 'Esercizi a corpo libero',
    'sport.weight.calisthenics': 'Calisthenics',
    'sport.weight.climbing': 'Arrampicata',
    'sport.weight.crossfit': 'Crossfit',
    'sport.weight.lifting': 'Sollevamento pesi',
    'sport.weight.powerlifting': 'Powerlifting',
    'sport.weight.strongman': 'Strongman',
    'sport.weight.weightlifting': 'Pesistica'
  }

  if(sportsMap[sportKey]){
    return sportsMap[sportKey]
  } else {
    return sportKey
  }
}