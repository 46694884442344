// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  changePassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ads: path(ROOTS_DASHBOARD, '/ads/list'),
    profile: path(ROOTS_DASHBOARD, '/profile')
  },
  ads: {
    root: path(ROOTS_DASHBOARD, '/ads'),
    list: path(ROOTS_DASHBOARD, '/ads/list'),
    new: path(ROOTS_DASHBOARD, '/ads/new'),
    show: path(ROOTS_DASHBOARD, '/ads/:id')
  },
  coupons: {
    root: path(ROOTS_DASHBOARD, '/coupons'),
    list: path(ROOTS_DASHBOARD, '/coupons/list')
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    list: path(ROOTS_DASHBOARD, '/users/list')
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/settings')
  }
};
