import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  currentAdv: null,
  advList: [],
  advListCount: 0
};

const slice = createSlice({
  name: 'adv',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE USERS
    deleteAdv(state, action) {
      const deleteAdv = filter(state.advList, (adv) => adv._id !== action.payload);
      state.advList = deleteAdv;
    },

    // GET MANAGE ADS
    getAdvListSuccess(state, action) {
      state.isLoading = false;
      state.advList = action.payload.rows;
      state.advListCount = action.payload.count;
    },

    // GET MANAGE ADS
    getCurrentAdvSuccess(state, action) {
        state.isLoading = false;
        state.currentAdv = action.payload;
    },

    resetCurrentAdv(state){
        state.currentAdv = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteAdv } = slice.actions;

// ----------------------------------------------------------------------

export function getAdvList(q = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/ads', {
        params: q
      });
      dispatch(slice.actions.getAdvListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdv(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/ads/${id}`);
      dispatch(slice.actions.getCurrentAdvSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetAdv() {
  return async (dispatch) => {
    dispatch(slice.actions.resetCurrentAdv());
  };
}
// ----------------------------------------------------------------------
