import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fDateTimeUtc(date) {
  // date is 2023-12-22T10:44:30+0000

  // return DD/MM/YYYY HH:MM in utc

  const dateParts = date.split('T');
  const datePart = dateParts[0];
  const timePart = dateParts[1];

  const timeParts = timePart.split('+');
  const time = timeParts[0];
  
  // split time into hours and minutes
  const timeParts2 = time.split(':');
  const hours = timeParts2[0];
  const minutes = timeParts2[1];

  const dateParts2 = datePart.split('-');
  const year = dateParts2[0];
  const month = dateParts2[1];
  const day = dateParts2[2];

  return `${day}/${month}/${year} ${hours}:${minutes} UTC`;
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
