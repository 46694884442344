import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'change-password',
          element: (
            <AuthGuard>
              <ChangePassword />
            </AuthGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'set-password', element: <SetNewPassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        {
          path: 'app',
          element: (
            <AuthGuard>
              <GeneralApp />
            </AuthGuard>
          )
        },
        {
          path: 'ads',
          children: [
            { element: <Navigate to="/dashboard/ads/list" replace /> },
            {
              path: 'list',
              element: (
                  <AdvList />
              )
            },
            {
              path: 'new',
              element: (
                  <AdvCreate />
              )
            },
            {
              path: ':id',
              element: (
                  <AdvEdit />
              )
            }
          ]
        },

        {
          path: 'coupons',
          children: [
            { element: <Navigate to="/dashboard/coupons/list" replace /> },
            {
              path: 'list',
              element: (
                  <CouponList />
              )
            }
          ]
        },

        {
          path: 'users',
          children: [
            { element: <Navigate to="/dashboard/users/list" replace /> },
            {
              path: 'list',
              element: (
                  <UserList />
              )
            }
          ]
        },

        { path: 'profile', element: <UserAccount /> },

        {
          path: 'settings',
          element: (
            <Setting />
          )
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const SetNewPassword = Loadable(lazy(() => import('../pages/authentication/SetNewPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const AdvList = Loadable(lazy(() => import('../pages/dashboard/AdvList')));
const AdvCreate = Loadable(lazy(() => import('../pages/dashboard/AdvCreate')));
const AdvEdit = Loadable(lazy(() => import('../pages/dashboard/AdvEdit')));

const CouponList = Loadable(lazy(() => import('../pages/dashboard/CouponList')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

const Setting = Loadable(lazy(() => import('../pages/dashboard/Setting')));


// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
