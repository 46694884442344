// routes
import homeFill from '@iconify/icons-eva/home-fill';
import { Icon } from '@iconify/react';
import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const getBoxIcon = (icon) => <Icon icon={icon} width={22} height={22} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.general.app,
        icon: getBoxIcon(homeFill)
      },
      { title: 'Ads', path: PATH_DASHBOARD.ads.list, icon: ICONS.booking },
      { title: 'Coupons', path: PATH_DASHBOARD.coupons.list, icon: ICONS.booking },
      { title: 'Users', path: PATH_DASHBOARD.users.list, icon: ICONS.user },
      { title: 'Profile', path: PATH_DASHBOARD.general.profile, icon: ICONS.user }
    ]
  }
];

export default sidebarConfig;
